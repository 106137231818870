import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from "react";
import Home from "./components/Home/Home";
import LawHelpPage from "./components/LawHelpPage/LawHelpPage";
import './styles/App.scss';
import ScrollToTop from './components/ScrollToTop';
import SubscriberService from './components/SubscriberService/SubscriberService';

const App = () => {
  return (
    <BrowserRouter>
      <div className="app">
        <ScrollToTop />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/legal-assistance" element={<LawHelpPage />} />
          <Route path="/subscriber-service" element={<SubscriberService />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
