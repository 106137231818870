import React from 'react'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from "react-scroll";

const SubscriberService = () => {

    const menuLinkItems = [
        { label: "Консультация", url: "footer" },
        { label: "Контакты", url: "contact" },
    ];
    const menuNavItems = [
        { label: "На главную", url: "/" },
        { label: "Юридическая помощь", url: "/legal-assistance" },
    ];

    return (
        <div>
            <div className='top'></div>
            <Header menuLinkItems={menuLinkItems} menuNavItems={menuNavItems} />
            <section className='subscriber-service'>
                <div className="subscriber-service__wrap">
                    <h1 className="section__title">Юридическое обслуживание бизнеса адвокатом</h1>
                    <div className="subscriber-service__description">
                        <p>Каждый субъект хозяйствования в ходе своей деятельности время от времени сталкивается с правовыми вопросами.</p> <br /> <p>Кто-то чаще, кто-то реже, но решать их надо. В современных реалиях решением юридических вопросов занимаются:</p>
                        <ul className='subscriber-service_ul'>
                            <li className='subscriber-service_ul-list'>руководители и бухгалтеры, которые самостоятельно изучают правовые вопросы;</li>
                            <li className='subscriber-service_ul-list'>штатный юрист;</li>
                            <li className='subscriber-service_ul-list'>юрист на аутсорсинге (адвокат, юрист-лицензиат).</li>
                        </ul>
                        <p>Наличие огромного объема информации на просторах интернета не всегда работает в пользу ищущего информацию. Если говорить о нормах права, то в большинстве своем мы натыкаемся на утратившие законную силу нормативно-правовые акты.</p>
                        <br /><p>Нанимать юриста или решать правовые вопросы самому – выбор каждого, как и ответственность за принятое решение.</p>
                        <br /><p>Если вы все-таки выбрали вариант доверить свои правовые вопросы юристу, то отличной возможностью получения квалифицированной помощи за приемлемые деньги станет заключение договора на юридическое (абонентское) обслуживание.</p>
                    </div>
                    <div className="subscriber-service__call-to-action">
                        <i> Если у Вас возникли вопросы, предлагаю записаться на консультацию</i>
                        <span></span>
                        <Link
                            to="footer"
                            spy={true}
                            smooth={true}
                            duration={500}
                            className="subscriber-service_request__btn"
                        >Запись на консультацию</Link>
                    </div>
                    <h2>Основными преимуществами абонентского обслуживания адвокатом являются:</h2>
                    <div className="subscriber-service-advantages">
                        <div className="subscriber-service-advantage">
                            <img src="accept.png" alt="img" />
                            Отсутствие необходимости наличия в штате юриста со всеми вытекающими последствиями (НЕТ ежемесячной заработной платы, обязательства перед бюджетом, выходного пособия, больничных, трудового контракта с необходимостью ждать его окончания для увольнения, НЕТ необходимости организовать рабочее место юриста).
                        </div>
                        <div className="subscriber-service-advantage">
                            <img src="accept.png" alt="img" />
                            Отсутствие необходимости нести расходы, связанные с приобретением правовой базы. (не секрет, что юристы и адвокаты пользуются правовыми базами Ilex, Нормативка, БизнесИнфо и пр., стоимость пользования которыми будет возложена на вас в истории со штатным юристом).
                        </div>
                        <div className="subscriber-service-advantage">
                            <img src="accept.png" alt="img" />
                            Оперативное получение квалифицированной юридической помощи по правовым вопросам.
                        </div>
                        <div className="subscriber-service-advantage">
                            <img src="accept.png" alt="img" />
                            Непрерывное самообразование адвоката за его счет и счет коллегии адвокатов, знание нововведений законодательства, общих тенденций, судебной практики.
                        </div>
                    </div>
                    <h2 style={{ marginTop: "90px" }}>Юридическая помощь при абонентском обслуживании</h2>
                    <div className="subscriber-service__assistance">
                        <div className="subscriber-service__assistance-item">
                            <img className='subscriber-service__assistance-item-img' src="call.png" alt="img" />
                            <p>Консультации руководителей и ответственных работников по возникшим вопросам</p>
                        </div>
                        <div className="subscriber-service__assistance-item">
                            <img className='subscriber-service__assistance-item-img' src="treaty.png" alt="img" />
                            <p>Разработка, актуализация шаблонов стандартных договоров, постоянно используемых в деятельности компании</p>
                        </div>
                        <div className="subscriber-service__assistance-item">
                            <img className='subscriber-service__assistance-item-img' src="cases.png" alt="img" />
                            <p>Разработка, актуализация корпоративных документов и сопровождение корпоративных решений</p>
                        </div>
                        <div className="subscriber-service__assistance-item">
                            <img className='subscriber-service__assistance-item-img' src="analysis.png" alt="img" />
                            <p>Анализ договоров контрагентов с целью защиты прав и законных интересов клиента</p>
                        </div>
                        <div className="subscriber-service__assistance-item">
                            <img className='subscriber-service__assistance-item-img' src="trial.png" alt="img" />
                            <p>Претензионная работа, ведение судебных дел</p>
                        </div>
                        <div className="subscriber-service__assistance-item">
                            <img className='subscriber-service__assistance-item-img' src="tribunal.png" alt="img" />
                            <p>Представление интересов перед государственными органами</p>
                        </div>
                        <div className="subscriber-service__assistance-item">
                            <img className='subscriber-service__assistance-item-img' src="query.png" alt="img" />
                            <p>Направление запросов и получение разъяснений от государственных органов</p>
                        </div>
                        <div className="subscriber-service__assistance-item">
                            <img className='subscriber-service__assistance-item-img' src="questions.png" alt="img" />
                            <p>Иные вопросы, возникающие в процессе осуществления деятельности компании</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default SubscriberService