import Footer from '../Footer/Footer';
import Header from "../Header/Header";
import { Link } from "react-scroll";
import React from "react";
import Text4 from './Text4';
import Text3 from './Text3';

const Home = () => {

    const menuLinkItems = [
        { label: "Обо мне", url: "about" },
        { label: "Консультация", url: "footer" },
        { label: "Контакты", url: "contact" },
    ];
    const menuNavItems = [
        { label: "Юридическая помощь", url: "/legal-assistance" },
        { label: "Абонентское обслуживание", url: "/subscriber-service" },
    ];

    return (
        <div>
            <div className='top'></div>

            <Header menuLinkItems={menuLinkItems} menuNavItems={menuNavItems} />

            <section className="section">
                <div className="section_wrapper">
                    <div className="intro">
                        <div className="title_info_btn">
                            <h1 className="title">Помощь юриста</h1>
                            <div className='info_btn'>
                                <div className="info">
                                    <div className="text1">Мое имя <b>Вакульчик Анастасия Андреевна</b> и я <b>адвокат!</b></div>
                                    <div className="text2">С моей помощью Ваша проблема найдет свое юридическое решение!</div>
                                </div>
                                <Link
                                    to="footer"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    className="request__btn"
                                >Запись на консультацию</Link>
                            </div>
                        </div>
                        <div className="photo__wrapper">
                            <div className="photo">
                                <img src="photo2.jpg" alt="img" />
                            </div>
                        </div>

                    </div>
                    <Link
                        to="about"
                        spy={true}
                        smooth={true}
                        duration={500}
                        className="arrow_container">
                        <div className="arrow"></div>
                        <div className="arrow"></div>
                        <div className="arrow"></div>
                    </Link>
                </div>
            </section>
            <section className="about" id="about">
                <div className="section_wrapper">
                    <div className="about-me">
                        <div className="about-me__title">Обо мне</div>
                        <div className="about-content">
                            <div className="about-photo">
                                <img src="photo.jpg" alt="img" />
                            </div>
                            <div className="about-text">
                                <div className="border_div">
                                    <p>Оказываю юридическую помощь в г. Гродно, г.п. Б.Берестовица, а также, на всей территории Республики Беларусь.</p>
                                </div>
                                <Text3 />
                            </div>
                        </div>
                        <Text4 />
                    </div>
                </div>
            </section>

            <section className="concept">
                <div className="section_wrapper">
                    <div className="section__title">Концепция</div>
                    <div className="concept_img_text">
                        <div className="image_k">
                            <img src="К.svg" alt="" />
                        </div>
                        <div className="concept_text">
                            <p>Конфиденциальность</p>
                            <p>Клиентоориентированность</p>
                            <p>Квалифицированность</p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Home