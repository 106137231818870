import React from 'react';

const Notification = ({ message }) => {

    return (
        <div className="notification">
            <div className="notification-wrap">
                <img src="done.png" alt="done" className='done' />
                <div className="message">{message}</div>
            </div>
        </div>
    )
}

export default Notification