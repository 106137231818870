import { useEffect, useState, useRef } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { NavLink } from "react-router-dom"

const Header = ({ menuNavItems, menuLinkItems }) => {

    const [background, setBackground] = useState(false);
    const [imageUrl, setImageUrl] = useState("/logo1.svg")
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(m => false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setBackground(window.pageYOffset > 200)
            );
            window.addEventListener("scroll", () => {
                if (window.pageYOffset > 200) {
                    setImageUrl("/logo.svg")
                } else {
                    setImageUrl("/logo1.svg")
                }
            });
        }
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop();
    };


    return (
        <>
            {!menuOpen ? (
                <header className={`header ${background ? "background" : ""}`}>
                    <div className="header__container">
                        <div onClick={scrollToTop} className="header__container-logo">
                            <img src={imageUrl} alt="" />
                        </div>
                        <ul className='header__container-menu '>
                            <div className={` header__container-menu_open ${menuOpen ? 'openly' : ''}`}>
                                {menuNavItems.map((item) => (
                                    <NavLink
                                        key={item.url}
                                        to={item.url}
                                        duration={500}
                                        className={`header__container-menu__link ${background ? "color" : ""}`}
                                    >
                                        {item.label}
                                    </NavLink>
                                ))}
                                {menuLinkItems.map((item) => (
                                    <Link
                                        key={item.url}
                                        to={item.url}
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className={`header__container-menu__link ${background ? "color" : ""} `}
                                    >
                                        {item.label}
                                    </Link>
                                ))}
                            </div>
                            <a href="tel:+375292853011" className={`header__container-menu__link_phone ${background ? "color" : ""}`}>+375 29 285-30-11</a>
                            <div className="mobile-menu" onClick={toggleMenu}>
                                <div className={`mobile-menu__line ${background ? "line-bg_w" : "line-bg_b"}`}></div>
                                <div className={`mobile-menu__line ${background ? "line-bg_w" : "line-bg_b"}`}></div>
                                <div className={`mobile-menu__line ${background ? "line-bg_w" : "line-bg_b"}`}></div>
                            </div>
                        </ul>
                    </div>
                </header>
            ) : (
                <header ref={menuRef} className={`header ${background ? "background" : ""} ${menuOpen ? 'header_open' : ''}`}>
                    <div className="header__container_open">
                        <div onClick={() => { scrollToTop(); toggleMenu() }} className="header__container-logo">
                            <img src={imageUrl} alt="" />
                        </div>
                        <ul className={`header__container_open-menu `}>
                            <div className={` header__container_open-menu-somth ${menuOpen ? 'openlyy' : ''}`}>
                                {menuNavItems.map((item) => (
                                    <NavLink
                                        key={item.url}
                                        to={item.url}
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className={`header__container_open-menu__link ${background ? "color" : ""}`}
                                        onClick={toggleMenu}
                                    >
                                        {item.label}
                                    </NavLink>
                                ))}
                                {menuLinkItems.map((item) => (
                                    <Link
                                        key={item.url}
                                        to={item.url}
                                        spy={true}
                                        smooth={true}
                                        duration={500}
                                        className={`header__container_open-menu__link ${background ? "color" : ""} `}
                                        onClick={toggleMenu}
                                    >
                                        {item.label}
                                    </Link>
                                ))}
                            </div>
                            <a href="tel:+375292853011" className={`header__container_open-menu__link_phone ${background ? "color" : ""}`} onClick={toggleMenu}>+375 29 285-30-11</a>
                            <div className="cross" onClick={toggleMenu}>
                                x
                            </div>
                        </ul>
                    </div>
                </header>
            )
            }
        </>
    )
}


export default Header;

