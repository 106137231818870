import React, { useState, useEffect, useRef } from "react";

const Text4 = () => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const Text4Ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (Text4Ref.current && !Text4Ref.current.contains(event.target)) {
        setExpanded((e) => false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [Text4Ref]);

  return (
    <div ref={Text4Ref} className={`text4 ${expanded ? "expanded" : ""}`}>
      <p>
        С сентября по декабрь 2018 года проходила стажировку в Гродненской
        областной коллегии адвокатов.
      </p>
      <p>
        С марта 2019 года – адвокат юридической консультации Берестовицкого
        района.
      </p>
      <p>
        С июня 2024 года – адвокат Специализированной юридической консультации
        г.Гродно.
      </p>
      <p>
        В 2018-2019 годах – преподаватель УО «Гродненский государственный
        университет имени Янки Купалы» различных дисциплин на кафедре
        гражданского права и процесса (Семейное право, Гражданский процесс,
        Трудовое право и пр.).
      </p>
      {!expanded ? (
        <button className="moreButton" onClick={toggleExpanded}>
          Подробнее
        </button>
      ) : (
        <button className="lessButton" onClick={toggleExpanded}>
          Свернуть
        </button>
      )}
    </div>
  );
};

export default Text4;
