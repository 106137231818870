import React, { useState, useEffect, useRef } from 'react'

const Text3 = () => {

    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const Text3Ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (Text3Ref.current && !Text3Ref.current.contains(event.target)) {
                setExpanded(e => false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [Text3Ref]);

    return (
        <div ref={Text3Ref} className={`text3 ${expanded ? 'expanded' : ''}`}>
            <p>В 2015 году с отличием окончила УО «Гродненский государственный университет имени Янки Купалы», юридический факультет специальность «Правоведение».</p>
            <p>В 2017 году – магистратуру по специальности «Юриспруденция».</p>
            <p>С сентября 2016 года – МЕДИАТОР, специализирующийся в сфере споров, возникающих в связи с осуществлением хозяйственной (экономической) деятельности.</p>
            <p>С июля 2014 года начала трудовую деятельность в качестве секретаря судебного заседания-помощника судьи в экономическом суде Гродненской области (до августа 2018 года).</p>
            {!expanded ? (
                <button className='moreButton' onClick={toggleExpanded}>
                    Подробнее
                </button>
            )
                :
                (
                    <button className='lessButton' onClick={toggleExpanded}>
                        Свернуть
                    </button>
                )}
        </div>
    )
}

export default Text3