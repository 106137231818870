import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios'
import InputMask from 'react-input-mask';
import Notification from './Notification';

const FooterForm = function () {

    const TOKEN = "6250366522:AAHgFxnnjHh1JaGTV6CB1nSNHCbnp3cK61o";
    const CHAT_ID = "-1001793832745";
    const URI_API = `https://api.telegram.org/bot${TOKEN}/sendMessage`;

    const validatePhone = (phone) => {
        const phoneRegex = /^\+375 \((29|25|44|33)\) \d{3}-\d{2}-\d{2}$/;
        return phoneRegex.test(phone);
    };

    const [formValues, setFormValues] = useState({
        name: '',
        phone: '',
        message: '',
    });
    const [formErrors, setFormErrors] = useState({
        name: '',
        phone: '',
        message: '',
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setFormErrors({
            ...formErrors,
            [name]: '',
        });
    };

    const [notification, setNotification] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = {};
        if (!formValues.name) {
            errors.name = 'Укажите Ваше имя';
        }

        if (!formValues.phone) {
            errors.phone = 'Укажите Ваш телефон';
        } else if (!validatePhone(formValues.phone)) {
            errors.phone = 'Введите корректный номер телефона';
        }
        if (!formValues.message) {
            errors.message = 'Укажите интересующий Вас вопрос';
        }

        if (Object.keys(errors).length === 0) {

            let info = `<b>Заявка с сайта!</b>\n`;
            info += `<b>Имя отправителя:</b> ${formValues.name}\n`;
            info += `<b>Телефон:</b> ${formValues.phone}\n`;
            info += `<b>Сообщение:</b> ${formValues.message}\n`;

            axios.post(URI_API, {
                chat_id: CHAT_ID,
                parse_mode: 'html',
                text: info
            })
                .then((res) => {
                    setFormValues({
                        name: '',
                        phone: '',
                        message: '',
                    });
                    setNotification('Сообщение отправлено!');
                    setTimeout(() => {
                        setNotification(null);
                    }, 3000);
                })
                .catch((err) => {
                    console.warn(err)
                })
                .finally(() => {
                    console.log("end!")
                })
        } else {
            setFormErrors(errors);
        }

    }

    const FormRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (FormRef.current && !FormRef.current.contains(event.target)) {
                setFormErrors('');
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [FormRef]);

    return (
        <div id="form" className="footer-container__form">
            <div className="footer-container__form-title">Чем я могу Вам помочь?</div>
            <div className="footer-container__border1"></div>
            <form ref={FormRef} className="form" encType="multipart/form-data" onSubmit={handleSubmit}>
                {notification && <Notification message={notification} onClose={() => setNotification(null)} />}
                <div className="form__item">
                    <div className="form__item-il">
                        <input
                            id="formName"
                            value={formValues.name}
                            onChange={handleInputChange}
                            placeholder=" "
                            type="text"
                            name="name"
                            className={`form__input ${formErrors.name ? 'form__input_error' : ''} `}
                            autoComplete="given-name"
                        />
                        <label htmlFor="formName" className="form__label">Ваше имя<span className='sup_color'>*</span></label>
                    </div>
                    {formErrors.name && <div className='form__item-errors'>{formErrors.name}</div>}
                </div>
                <div className="form__item">
                    <div className="form__item-il">
                        <InputMask
                            id="formPhone"
                            value={formValues.phone}
                            onChange={handleInputChange}
                            placeholder=" "
                            type="tel"
                            name="phone"
                            mask="+375 (99) 999-99-99"
                            className={`form__input ${formErrors.phone ? 'form__input_error' : ''} `}
                            autoComplete="off"
                        />
                        <label htmlFor="formPhone" className="form__label">Ваш номер телефона<span className='sup_color'>*</span></label>
                    </div>
                    {formErrors.phone && <div className='form__item-errors'>{formErrors.phone}</div>}
                </div>
                <div className="form__item">
                    <div className="form__item-il">
                        <textarea
                            name="message"
                            value={formValues.message}
                            onChange={handleInputChange}
                            id="formMessage"
                            placeholder=" "
                            className={`form__textarea ${formErrors.message ? 'form__input_error' : ''} `}
                            autoComplete="off"
                        >
                        </textarea>
                        <label htmlFor="formMessage" className="form__label-textarea">Введите сообщение<span className='sup_color'>*</span></label>
                    </div>
                    {formErrors.message && <div className='form__item-errors'>{formErrors.message}</div>}
                </div>
                <div className="form__btn_description">
                    <button id="submit" type="submit" className="form__btn">Отправить</button>
                    <div className="form__description">В течение некоторого времени я с Вами свяжусь.</div>
                </div>
            </form>
        </div>
    )
}

export default FooterForm;