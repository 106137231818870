import React from "react";
import FooterForm from "./FooterForm";

const Footer = function () {
  return (
    <footer className="footer" id="footer">
      <div className="footer-container">
        <div className="footer-container__border2"></div>
        <div className="footer-container__border3"></div>
        <div className="footer-container__content">
          <FooterForm />
          <div id="contact" className="contact-box">
            <div className="footer-container__title-contact">Контакты</div>
            <div className="contact-box__title">Телефон:</div>
            <a href="tel:+375292853011" className="contact-box__link">
              +375 29 285-30-11
            </a>
            <div className="contact-box__title">Адрес:</div>
            <p>
              Республика Беларусь, г.Гродно, ул.К.Маркса, д.15, 2 этаж, 1 каб.
            </p>
            <a
              href="https://yandex.ru/maps/?whatshere[point]=23.833679, 53.677626&whatshere[zoom]=19"
              className="contact-box__link_map"
              target="_blank"
              rel="noreferrer noopener"
            >
              <em>Посмотреть на карте</em>
            </a>
            <div className="contact-box__title">e-mail:</div>
            <a
              href="mailto:filonova_anastasiya@mail.ru"
              className="contact-box__link"
            >
              filonova_anastasiya@mail.ru
            </a>
            <div className="contact-box__title">Лицензия:</div>
            <p className="contact-box__license">
              02240/3024 от 15 февраля 2019
            </p>
            <div className="contact-box__sn">
              <a
                href="https://instagram.com/advokat_vakulchik?igshid=YmMyMTA2M2Y="
                className="contact-box__sn-icon"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src="instagram.png" alt="instagram" />
              </a>
              <a
                href="viber://chat?number=%2B375292853011"
                className="contact-box__sn-icon"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src="viber.png" alt="viber" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
