import { useState } from 'react'

const GridItems = () => {

    const items = [
        {
            id: 1,
            title: 'Экономические споры',
            descr: 'помогаю защитить интересы клиента при конфликтах в сфере бизнеса.',
        },
        {
            id: 2,
            title: 'Гражданские споры',
            descr: 'предоставление юридической помощи и защиты в гражданских делах.',
        },
        {
            id: 3,
            title: 'Административные дела',
            descr: 'представляю интересы в административных спорах, в том числе внесудебном порядке.',
        },
        {
            id: 4,
            title: 'Налоговые споры',
            descr: 'помогу решить мероприятия, связанные с налоговыми органами и таможней в РБ.',
        },
        {
            id: 5,
            title: 'Корпоративное право',
            descr: 'оказание юридических услуг по вопросам корпоративной деятельности.',
        },
        {
            id: 6,
            title: 'Семейные споры',
            descr: 'помощь при разводе, определении места жительства и общении с детьми, разделе имущества.',
        },
        {
            id: 7,
            title: 'Уголовные дела',
            descr: 'защита прав и интересов в уголовном процессе, представление в суде и рассмотрении дела.',
        },
        {
            id: 8,
            title: 'Медицинское право',
            descr: 'помощь в решении споров пациентов с медицинскими учреждениями, выявление нарушений.',
        },
        {
            id: 9,
            title: 'Исполнительное производство',
            descr: 'поможем защитить права и интересы в ходе исполнительных производств в РБ.',
        },
        {
            id: 10,
            title: 'Жилищные споры',
            descr: 'помощь в решении споров между собственниками жилплощади.',
        },
        {
            id: 11,
            title: 'Наследственные правоотношения',
            descr: 'получение наследства, защита прав на наследство, раздел имущества между наследниками.',
        },
        {
            id: 12,
            title: 'Лицензирование',
            descr: 'консультации и помощь в прохождении различных процедур лицензирования бизнеса и деятельности.',
        },
    ]



    const [expandedId, setExpandedId] = useState(null);

    const handleExpand = (id) => {
        if (expandedId === id) {
            setExpandedId(null);
        } else {
            setExpandedId(id);
        }
    };



    return (
        <>
            {items.map((item) => (
                <div
                    className="grid-item"
                    key={item.id} >
                    <div className="grid-item__title">{item.title}</div>
                    <div
                        className={`grid-item__description `}
                        style={{ display: expandedId === item.id ? "block" : "" }}
                    >
                        &ndash; {item.descr}
                    </div>
                    {expandedId === item.id ? (
                        <button className='lessButton-grid' onClick={() => handleExpand(item.id)}>
                            Свернуть
                        </button>

                    )
                        :
                        (
                            <button className='moreButton-grid' onClick={() => handleExpand(item.id)}>
                                Подробнее
                            </button>
                        )}
                </div>
            ))
            }
        </>
    )
}

export default GridItems