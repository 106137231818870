import React from "react";
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import GridItems from "./GridItems";

const LawHelpPage = () => {

    const menuLinkItems = [
        { label: "Консультация", url: "footer" },
        { label: "Контакты", url: "contact" },
    ];
    const menuNavItems = [
        { label: "На главную", url: "/" },
        { label: "Абонентское обслуживание", url: "/subscriber-service" },
    ];

    return (
        <div>
            <div className='top'></div>
            <Header menuLinkItems={menuLinkItems} menuNavItems={menuNavItems} />
            <section className="law">
                <div className="law_wrapper">
                    <div className="lawhelp">
                        <h1 className="section__title">Юридическая помощь</h1>
                        <div className="lawhelp__content">
                            <ul className="service-list">
                                <li className="service-list__item">консультирование и разъяснение по юридическим вопросам;</li>
                                <br /><li className="service-list__item">составление заявлений, жалоб и других документов правового характера;</li>
                                <br /><li className="service-list__item">представление интересов клиентов в судах, в том числе на стадии исполнения судебных постановлений, а также в государственных органах, иных организациях, в том числе их органах управления, и перед физическими лицами;
                                    участие в досудебном производстве и суде по уголовным делам в качестве защитника, а также представителя потерпевших, гражданских истцов, гражданских ответчиков;
                                </li>
                                <br /><li className="service-list__item">участие в административном процессе в качестве защитника, представителя потерпевшего, иных физических или юридических лиц, являющихся участниками административного процесса;</li>
                                <br /><li className="service-list__item">проведение правовой оценки документов и деятельности;</li>
                                <br /><li className="service-list__item">ведение правовой работы по обеспечению хозяйственной и иной деятельности;</li>
                                <br /><li className="service-list__item">веду правовую работу по вопросам привлечения инвестиций в Республику Беларусь;</li>
                                <br /><li className="service-list__item">совершение от имени и в интересах клиентов юридически значимых действий в пределах полномочий, предоставленных им клиентом и законодательством;</li>
                                <br /><li className="service-list__item">оказание иных видов юридической помощи.</li>
                            </ul>
                            <div className="grid-container">
                                <GridItems />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default LawHelpPage